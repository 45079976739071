
import './App.css';
// import Intro from './components/Intro';
// import Header from './components/Header';
// import Banner from './components/Banner';
// import Service from './components/Service';
// import Team from './components/Team';
// import Testimonial from './components/Testimonial';
import Contact from './components/Contact';
// import Footer from './components/Footer';

function App() {
  return (
    <div className="App" id="scrolltop">
        {/* <Header />
        <Banner />
        <Intro />
        <Service />
        <Team />
        <Testimonial /> */}
        <Contact />
        {/* <Footer /> */}
    </div>
  );
}

export default App;
